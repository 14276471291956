<template>
  <div class="viewtab" v-loading="loading">
    <div class="tabform">
      <div class="tabform-tit">
        <el-form ref="form" :model="form" label-width="110px" class="flex">
          <el-form-item label="姓名：">
            <el-input v-model="form.name" class="name" clearable></el-input>
          </el-form-item>
          <el-form-item label="权限类别：">
            <el-select
              v-model="form.label"
              placeholder="请选择权限类别"
              class="region"
              clearable
            >
              <el-option label="全部" value=""></el-option>
              <el-option label="普通用户" value="普通用户"></el-option>
              <el-option label="超级管理员" value="超级管理员"></el-option>
              <el-option label="工程技术人员" value="工程技术人员"></el-option>
            </el-select>
            <el-button type="primary" class="subtn" @click="search"
              >搜索</el-button
            >
          </el-form-item>
          <div style="margin-left: 3vw">
            <el-button type="primary" @click="onSubmit1()" class="subtn"
              >通讯总览</el-button
            >
          </div>
          <div style="margin-left: 3vw">
            <el-button type="primary" @click="onSubmit()" class="subtn"
              >新增用户</el-button
            >
          </div>
          <div style="margin-left: 3vw">
            <el-button type="primary" @click="onSubmit2()" class="subtn"
              >日志查询</el-button
            >
          </div>
        </el-form>
      </div>
      <el-table
        :data="newArry"
        border
        style="width: 100%"
        height="60vh"
        fixed
        @row-click="openDetails"
      >
        <el-table-column prop="account" label="账户名" align="center">
        </el-table-column>
        <el-table-column prop="name" label="姓名" align="center">
        </el-table-column>
        <el-table-column prop="phone" label="手机号" align="center">
        </el-table-column>
        <el-table-column prop="email" label="邮箱" align="center">
        </el-table-column>
        <el-table-column prop="auth_group" label="权限类别" align="center">
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template>
            <el-button type="danger" size="small">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageSizes"
        :page-size="PageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="tit"
      :visible.sync="centerDialogVisible"
      width="55vw"
      center
      @closed="closeD"
      
    >
      <el-form
        :label-position="labelPosition"
        label-width="80px"
        :model="formLabelAlign"
        :inline="true"
        class="addform"
        :rules="rules"
        ref="ruleForm"
      >
        <el-form-item label="姓名" prop="name">
          <el-input
            v-model="formLabelAlign.name"
            clearable
            placeholder="请输入姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="账户名" prop="account">
          <el-input
            v-model="formLabelAlign.account"
            clearable
            placeholder="请输入账户名(中文)"
            :disabled="disabled"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input
            v-model="formLabelAlign.phone"
            clearable
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input
            v-model="formLabelAlign.email"
            clearable
            placeholder="请输入邮箱"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="psd">
          <el-input
            v-model="formLabelAlign.psd"
            clearable
            placeholder="请输入密码"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="权限类别">
          <el-select
            v-model="logintype"
            placeholder="请选择"
            @change="adminChange"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-input placeholder="在此可输入项目名称进行搜索" v-model="filterText">
        </el-input>
        <el-form-item label="权限">
          <div class="flex kuandu">
            <el-tree
              v-if="treeshow"
              ref="tree"
              :data="treeData"
              show-checkbox
              node-key="id"
              :default-expand-all="expandall"
              :default-checked-keys="checkTrue"
              :props="defaultProps"
              :filter-node-method="filterNode"
              @check="checkHandler"
              v-loading="loading"
              accordion
            >
            </el-tree>
          </div>
        </el-form-item>
      </el-form>
      <el-button
        type="primary"
        :icon="elicon"
        class="btnedit"
        @click="creatUser('ruleForm')"
        >确定</el-button
      >
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible_log"
      width="70%"
      :before-close="handleClose"
    >
      <el-table :data="tableData_log" style="width: 100%">
        <el-table-column prop="date" label="日期" width="180">
        </el-table-column>
        <el-table-column prop="user" label="操作人" width="180">
        </el-table-column>
        <el-table-column prop="event" label="事件"> </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
import { getAdminlist } from "@/api/otherview/index"; //权限菜单
import { getAdduser } from "@/api/otherview/index"; //添加用户
import { getUser } from "@/api/otherview/index"; //获取用户
import { authUser } from "@/api/otherview/index"; //已授权菜单
import { getEdituser } from "@/api/otherview/index"; // 修改用户权限
import { number } from "echarts";
import Format from "../../utils/auth.js";
import { log_show, log_add, getlogin, loginAdmin } from "@/api/auth/index";
export default {
  name: "userAdmin",
  data: () => {
    return {
      vaidAccount: [],
      tableData_log: [],
      newArry: [],
      filterText: "",
      currentPage: 1, //初始页
      PageSize: 10, //每页的数据
      totalCount: 0,
      pageSizes: [10, 20, 30, 40, 50, 100],
      currentPage1: 1, //初始页
      PageSize1: 10, //每页的数据
      totalCount1: 0,
      pageSizes1: [10, 20, 30, 40, 50, 100],
      disabled: false,
      type: 1, // 1新增用户 2修改用户
      treeshow: true,
      elicon: "",
      labelPosition: "right",
      checkAd: [],
      treeData: [],
      organList: [],
      loading: true,
      dialogVisible_log: false,
      defaultProps: {
        children: "children",
        label: "description",
      },
      authList: [],
      options: [
        {
          value: 0,
          label: "普通用户",
        },
        {
          value: 1,
          label: "超级管理员",
        },
        {
          value: 2,
          label: "工程技术人员",
        },
      ],
      logintype: 0,
      auth_group: "普通用户",
      checkTrue: [],
      tit: "新增用户",
      centerDialogVisible: false,
      formLabelAlign: {
        name: "",
        account: "",
        phone: "",
        psd: "",
        email: "",
      },
      UserInfo: {},
      form: {
        name: "",
        label: "",
      },
      tableData: [],
      expandall: false,
      psdshow: true,
      rules: {
        name: [
          {
            validator: Format.FormValidate.Form().Name, //validator： 自定义验证规则
            trigger: "blur",
            required: true,
          },
        ],
        account: [
          {
            validator: Format.FormValidate.Form().Account,
            trigger: "blur",
            required: true,
          },
        ],
        phone: [
          {
            validator: Format.FormValidate.Form().Tel,
            trigger: "blur",
            required: true,
          },
        ],
        email: [
          {
            validator: Format.FormValidate.Form().Email,
            trigger: "blur",
            required: true,
          },
        ],
        psd: [
          {
            // validator: Format.FormValidate.Form().Psd,
            trigger: "blur",
            required: true,
          },
        ],
      },
    };
  },
  created() {
    if (this.$route.query.type == 1) {
      this.user = this.$route.query.user;
      this.login(this.user);
    }
    this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
    // console.log(this.UserInfo, "当前登录人");

    this.gethisInfo();
    this.getUserList();
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  methods: {
    login(user) {
      getlogin({
        account: user,
      }).then((res) => {
        // console.log(res, "用户名密码");
        if (res) {
          this.username = res.a;
          this.password = res.p;
          loginAdmin({
            account: this.username,
            password: this.password,
          }).then((res) => {
            // console.log("1111", res);
            if (res.info.code == 500) {
              return this.$message.error(res.info.msg);
            } else {
              localStorage.setItem("UserInfo", JSON.stringify(res.msg));
              localStorage.setItem("authInfo", JSON.stringify(res.auth));
            }
          });
        } else {
          return this.$message.error("登录异常，请联系管理员进行解决");
        }
      });
      this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
      //   console.log(this.UserInfo, "当前登录人");
    },
    // 项目列表筛选
    filterNode(value, data) {
      if (!value) return true;
      return data.pid_name.indexOf(value) !== -1;
    },
    // 搜索
    search() {
      let filterArr = [];
      let filterArr1 = [];
      if (this.form.name && this.form.label == "") {
        getUser({
          name: this.form.name,
        })
          .then((res) => {
            this.loading = false;
            for (let item of res) {
              if (item.name === this.form.name) {
                filterArr.push(item);
              }
            }
            this.tableData = filterArr;
            for (const i in filterArr) {
              this.vaidAccount.push(filterArr[i].account);
            }
            this.getResultsData();
          })
          .catch((err) => {
            // this.$message.error('历史数据获取失败');
          });
      } else if (this.form.label && this.form.name == "") {
        getUser({
          name: this.form.name,
          auth_group: this.form.label,
        })
          .then((res) => {
            this.loading = false;
            for (let item of res) {
              if (item.auth_group === this.form.label) {
                filterArr.push(item);
              }
            }
            this.tableData = filterArr;
            for (const i in filterArr) {
              this.vaidAccount.push(filterArr[i].account);
            }
            this.getResultsData();
          })
          .catch((err) => {
            // this.$message.error('历史数据获取失败');
          });
      } else if (this.form.label && this.form.name) {
        getUser({
          auth_group: this.form.label,
        })
          .then((res) => {
            this.loading = false;
            for (let item of res) {
              if (item.name === this.form.name) {
                filterArr.push(item);
              }
            }
            for (let item of filterArr) {
              if (item.auth_group === this.form.label) {
                filterArr1.push(item);
              }
            }

            this.tableData = filterArr1;
            for (const i in filterArr1) {
              this.vaidAccount.push(filterArr1[i].account);
            }
            this.getResultsData();
          })
          .catch((err) => {
            // this.$message.error('历史数据获取失败');
          });
      } else {
        this.getUserList();
      }
    },
    handleSizeChange(val) {
      this.PageSize = val;
      this.getResultsData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getResultsData();
    },
    //前端自己分页
    getResultsData() {
      // this指向改一下
      let that = this;
      let list = that.tableData; //后端回来表格的数据
      //渲染的数据newArry赋值
      this.newArry = list.filter(
        (item, index) =>
          index < that.currentPage * that.PageSize &&
          index >= that.PageSize * (that.currentPage - 1)
      ); //根据页数显示相应的内容
      this.totalCount = list.length;
    },
    // 通讯总览
    onSubmit1() {
      this.$router.push({
        path: "/otherPage",
      });
    },
    handleClose() {
      this.dialogVisible_log = false;
    },
    // 日志查询
    onSubmit2() {
      this.dialogVisible_log = true;
      log_show().then((res) => {
        // console.log(res, "日志内容");
        this.tableData_log = res;
      });
    },
    //   获取用户列表
    getUserList() {
      getUser({
        name: this.form.name,
        auth_group: this.form.label,
      })
        .then((res) => {
          this.loading = false;
          this.tableData = res;
          //   console.log(res);
          for (const i in res) {
            this.vaidAccount.push(res[i].account);
          }
          this.getResultsData();
        })
        .catch((err) => {
          // this.$message.error('历史数据获取失败');
        });
    },
    //   创建新用户
    creatUser(formName) {
      let checkedKeys = this.$refs.tree.getCheckedNodes(true);
      //   console.log(checkedKeys, 1111);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.type == 1) {
            for (const i in this.vaidAccount) {
              if (this.formLabelAlign.account == this.vaidAccount[i]) {
                return this.$message.error("用户名已存在");
              }
            }
            // for (let i in checkedKeys) {
            // 	checkedKeys[i].checked = true
            // 	// delete checkedKeys[i].children
            // }
            this.authList = checkedKeys;
            // console.log(this.authList, 1111);
            getAdduser({
              name: this.formLabelAlign.name,
              account: this.formLabelAlign.account,
              phone: this.formLabelAlign.phone,
              password: this.formLabelAlign.psd,
              email: this.formLabelAlign.email,
              auth_group: this.auth_group,
              auth: this.authList,
            })
              .then((res) => {
                if (res.msg == 0) {
                  this.$message.error("手机号已被注册");
                } else {
                  const data = {
                    user: this.UserInfo[1],
                    event: "新增用户" + this.formLabelAlign.name,
                  };
                  log_add(data);
                  this.$message.success("添加成功");
                  location.reload();
                  setTimeout(() => {
                    this.centerDialogVisible = false;
                  }, 100);
                }
              })
              .catch((err) => {
                this.$message.error("添加失败");
              });
          } else {
            let checkedKeys = this.$refs.tree.getCheckedNodes(true);
            // for (let i in checkedKeys) {
            // 	checkedKeys[i].checked = true
            // 	delete checkedKeys[i].children
            // }
            this.authList = checkedKeys;
            // console.log(this.authList, 1111);
            // const data = {
            //   name: this.formLabelAlign.name,
            //   account: this.formLabelAlign.account,
            //   phone: this.formLabelAlign.phone,
            //   password: this.formLabelAlign.psd,
            //   email: this.formLabelAlign.email,
            //   auth_group: this.auth_group,
            //   auth: this.authList,
            // };
            // console.log(data, "参数");
            getEdituser({
              name: this.formLabelAlign.name,
              account: this.formLabelAlign.account,
              phone: this.formLabelAlign.phone,
              password: this.formLabelAlign.psd,
              email: this.formLabelAlign.email,
              auth_group: this.auth_group,
              auth: this.authList,
            })
              .then((res) => {
                const data = {
                  user: this.UserInfo[1],
                  event: "修改用户" + this.formLabelAlign.name,
                };
                log_add(data);
                this.$message.success(res.msg);
                // location.reload();
                setTimeout(() => {
                  this.centerDialogVisible = false;
                }, 100);
              })
              .catch((err) => {
                this.$message.error("修改失败");
              });
          }
        }
      });
    },
    //   获取权限菜单
    gethisInfo() {
      //   console.log(this.UserInfo[2], "用户");
      getAdminlist({ name: this.UserInfo[2] })
        .then((res) => {
          //console.log(res,"权限")
          this.checkAd = res.list;
          this.organList = res.list.map((a) => ({
            label: a.description,
            value: a.id,
          }));
          //   console.log(111)
          this.getListData();
        })
        .catch((err) => {
          // this.$message.error('历史数据获取失败');
        });
    },
    //对json的格式的转化
    getListData() {
      // console.log(this.checkAd)
      let dataArray = [];
      this.checkAd.forEach(function (data) {
        // console.log(data)
        let parentId = data.pid;
        if (parentId === 0) {
          let objTemp = {
            id: data.id,
            description: data.description,
            checked: data.checked,
            pid: parentId,
            pid_name: data.pid_name,
          };

          dataArray.push(objTemp);
        }
      });
      //console.log(this.checkAd)
      //console.log(dataArray);
      //this.treeData = dataArray;
      this.treeData = this.data2treeDG(this.checkAd, dataArray);
    },
    data2treeDG(datas, dataArray) {
      //    console.log(datas)
      //    return;
      for (let j in dataArray) {
        let dataArrayIndex = dataArray[j];
        //   console.log(dataArrayIndex)
        let childrenArray = [];
        let Id = dataArrayIndex.id;
        for (let i in datas) {
          let data = datas[i];
          let parentId = data.pid;
          if (parentId == Id && parentId !== data.id) {
            //判断是否为儿子节点
            let objTemp = {
              id: data.id,
              description: data.description,
              pid: parentId,
              checked: data.checked,
              pid_name: data.pid_name,
            };
            childrenArray.push(objTemp);
          }
        }
        dataArrayIndex.children = childrenArray;
        if (childrenArray.length > 0) {
          //有儿子节点则递归
          this.data2treeDG(datas, childrenArray);
        }
      }
      //   console.log(dataArray);
      return dataArray;
    },

    checkHandler(...value) {
      // tree勾选的方法
      let checkedKeys = this.$refs.tree.getCheckedNodes();
      //  console.log()
      //  let parche = value[1].halfCheckedNodes[1].checked
      for (let i in checkedKeys) {
        checkedKeys[i].checked = true;
      }

      this.authList = checkedKeys;
    },
    //   权限类型选择
    adminChange() {
      if (this.logintype == 1) {
        this.auth_group = "超级管理员";
        this.$refs.tree.setCheckedNodes(this.checkAd);
        for (let i in this.checkAd) {
          this.checkAd[i].checked = true;
        }
      } else {
        this.logintype == 0
          ? (this.auth_group = "普通用户")
          : (this.auth_group = "工程技术人员");
        this.$refs.tree.setCheckedNodes([]);
        for (let i in this.checkAd) {
          this.checkAd[i].checked = false;
        }
      }
      // this.checkTrue=[]
    },
    // 新增用户
    onSubmit() {
      this.disabled = false;
      this.type = 1;
      this.treeshow = true;
      this.elicon = "el-icon-plus";
      this.centerDialogVisible = true;
      this.psdshow = true;
      this.tit = "新增用户";
      this.formLabelAlign.name = "";
      this.formLabelAlign.email = "";
      this.formLabelAlign.account = "";
      this.formLabelAlign.phone = "";
      this.formLabelAlign.psd = "";
      this.logintype = 0;
      if (this.$refs["ruleForm"]) {
        this.$refs["ruleForm"].clearValidate();
      }
      this.$nextTick(function () {
        this.$refs.tree.setCheckedNodes([]);
      });
    },
    // 修改用户
    openDetails(row) {
      this.type = 2;
      this.disabled = true;
      this.treeshow = true;
      this.elicon = "el-icon-edit";
      this.centerDialogVisible = true;
      this.psdshow = false;
      this.tit = "修改用户";
      this.formLabelAlign.name = row.name;
      this.formLabelAlign.account = row.account;
      this.formLabelAlign.phone = row.phone;
      this.formLabelAlign.psd = row.password;
      this.formLabelAlign.email = row.email;

      if (row.auth_group == "超级管理员") {
        this.logintype = 1;
      } else if (row.auth_group == "普通用户") {
        this.logintype = 0;
      } else if (row.auth_group == "工程技术人员") {
        this.logintype = 2;
      }
      this.auth_group = row.auth_group;
      this.loading = true;
      this.authtokenUser(row.account);
      if (this.$refs["ruleForm"]) {
        this.$refs["ruleForm"].clearValidate();
      }
      //   console.log(this.logintype);
      //  if(this.logintype == 1){
      //      this.$nextTick(function () {
      //     this.$refs.tree.setCheckedNodes(this.checkAd);
      //         });
      // }else{

      // }
    },
    // 已授权菜单
    authtokenUser(name) {
      this.checkTrue = [];
      authUser({
        account: name,
      })
        .then((res) => {
          let arrauth = [];
          for (let i in res) {
            if (res[i].checked) {
              arrauth.push(res[i].id);
            }
          }
          this.checkTrue = arrauth;
          this.loading = false;
        })
        .catch((err) => {
          // this.$message.error('历史数据获取失败');
        });
    },
    closeD() {
      this.treeshow = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.viewtab {
  width: 96vw;
  height: calc(100vh - 88px);
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 6px 22px 0px rgba(83, 93, 113, 0.06);
  border-radius: 6px;
  margin: 20px auto;

  .tabform {
    padding: 20px;
  }
}

.btnedit {
  width: 10vw;
  margin: 0 auto;
  display: block;
  background-image: linear-gradient(
      -45deg,
      #0b385f 3%,
      #005071 19%,
      #10526b 51%,
      #205365 100%
    ),
    linear-gradient(#205161, #205161);
  background-blend-mode: normal, normal;
  border: none;
}

::v-deep {
  .name {
    width: 18vw;
  }

  .region {
    width: 20vw;
    padding-right: 20px;
  }

  .subtn {
    width: 7vw;
    height: 42px;
    background-color: #168db2 !important;
    border-radius: 2px;
    border: none;
  }

  .el-table--border td {
    color: #2d2d2d;
    cursor: pointer;
  }

  .el-table__header th {
    background-color: rgba(195, 202, 215, 0.1) !important;
    font-size: 16px;
    font-family: PingFang-SC-Regular;
  }

  .el-pagination {
    margin-top: 15px;
  }

  .addform {
    //   text-align: center;
    .el-form-item__content {
      width: 17vw;
    }

    .el-select {
      width: 100%;
    }

    .el-tree-node__content {
      height: 40px !important;
    }

    .el-tree {
      display: flex;
    }
  }
  .kuandu{
    width: 48vw;
    height: 50vh;
    overflow: auto; /* 允许横向滚动 */
  }
  .el-tree {
  display: flex;
  flex-direction: column; /* 设置为竖向排列 */
}

.el-tree .el-tree-node {
  display: block; /* 确保每个节点块级显示 */
}
}
</style>
